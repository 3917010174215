import axios, {AxiosResponse} from "axios";
import {
    ResponseType,
    StatisticsDataType,
    StatusType,
    TerminalsDataType,
    TransactionsDataType,
} from "./types";
import {getToken} from "../utils/utils";

const instance = axios.create({
    // baseURL: process.env.REACT_APP_API_URL,
    headers: {}
})
export type LoginParamsType = {
    login: string
    password: string
}

export const personalAreaAPI = {
    getFilteredStatistics(data: StatisticsParamsType) {
        return instance.get<StatisticsDataType>(`backoffice/personal-area/statistics?column=createdAt`, {
            params: data,
            headers: {Authorization: `Bearer ${getToken() || ''}`},
            withCredentials: true,
        });
    },
    getFilteredTransactions(data: TransactionsParamsType) {
        let url = `backoffice/personal-area/transactions`
        return instance.get<TransactionsDataType>(url, {
            params: data,
            headers: {Authorization: `Bearer ${getToken() || ''}`},
            withCredentials: true,
        });
    },
    getTerminals() {
        return instance.get<TerminalsDataType>(`backoffice/personal-area/terminals`, {
            headers: {Authorization: `Bearer ${getToken() || ''}`},
            withCredentials: true,
        });
    },
    getUnloadData(data: TransactionsParamsType) {
        return instance.get<any>(`backoffice/personal-area/download?column=createdAt`, {
            params: data,
            headers: {Authorization: `Bearer ${getToken() || ''}`},
            withCredentials: true,
        });
    },
    getRegisters(data: RegistersParamsType) {
        return instance.get<any>(`backoffice/personal-area/reports?`, {
            params: data,
            headers: {Authorization: `Bearer ${getToken() || ''}`},
            withCredentials: true,
        })
    },
    makeRegister(data: RegistersParamsType) {
        return instance.get<any>(`backoffice/personal-area/make-report`, {
            params: data,
            headers: {Authorization: `Bearer ${getToken() || ''}`},
            withCredentials: true,
        })
    }
}


export const authApi = {
    login(data: LoginParamsType) {
        return instance.post<ResponseType, AxiosResponse<ResponseType>, any>('backoffice/auth/login', data)
    }
}

export type StatisticsParamsType = {
    bank?: string
    type?: string
    terminal?: string
    billingType?: string
    createdFrom?: string | Date
    createdTo?: string | Date
}
export type TransactionsParamsType = {
    quantity?: number
    page?: number
    bank?: string
    cardNumber?: string
    extId?: string
    uid?: string
    _id?: string
    status?: StatusType | string
    billingType?: string
    type?: string
    terminalId?: string
    createdFrom?: string
    createdTo?: string
    column?: string
}
export type RegistersParamsType = {
    filename?:string
    quantity?: number
    createdFrom?: string
    createdTo?: string
    column?: string
    type?: string
    page?: number
    status?: StatusType | string
    order?: string
    columnSort?: string
    terminal?: string
    billingType?: string
}