import {applyMiddleware, combineReducers, createStore} from "redux";
import {thunk} from "redux-thunk";
import {authReducer} from "./auth-reducer";
import {appReducer} from "./appReducer";
import {statisticsReducer} from "./statisticsReducer";
import {transactionsReducer} from "./transactionsReducer";
import {terminalsReducer} from "./terminalsreducer";
import {RegistersReducer} from "./RegisterReducer";

export const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    statistics: statisticsReducer,
    transactions: transactionsReducer,
    terminals: terminalsReducer,
    registers: RegistersReducer
})
export type AppRootStateType = ReturnType<typeof rootReducer>

export const store = createStore(rootReducer, applyMiddleware(thunk))

// @ts-ignore
window.store = store