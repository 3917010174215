import {
    StatisticsType,
    TotalStatisticsType, TransactionsDataType, TransactionsType, TransactionTableChangeType
} from "./types";
import {Dispatch} from "redux";
import {setAppStatusAC} from "./appReducer";
import {personalAreaAPI, StatisticsParamsType, TransactionsParamsType} from "./axios.api";
import {handleServerNetworkError} from "../utils/error-utils";
import {setStatisticsAC} from "./statisticsReducer";
import {prettifyAmount} from "../utils/utils";


type SetTransactionsACType = {
    type: 'SET-TRANSACTIONS'
    transactions: TransactionsType[]
    page: number
    pages: number
    totalCount: number
}
type SetCHANGEDTransactionsACType = {
    type: 'SET-CHANGED-TRANSACTIONS'
    changedTransactionsState: TransactionTableChangeType
}
type SetFilteredTransactionsACType = {
    type: 'SET-FILTERED-TRANSACTIONS'
    filteredTransactions: TransactionsParamsType
}

type ActionsType = SetTransactionsACType | SetCHANGEDTransactionsACType | SetFilteredTransactionsACType

const changedTransactionsState = {
    status: true,
    type: true,
    id: true,
    uid: true,
    extId: true,
    terminal: true,
    createdTo: true,
    createdFrom: true,
    quantity: true,
    currency: true,
    propNumber: true,
    message: true,
    bank: true
}
const initialState: TransactionsDataType = {
    transactions: [],
    page: 1,
    pages: 0,
    totalCount: 0,
    changedTransactionsState: JSON.parse(localStorage.getItem('transactionChecks') || JSON.stringify(changedTransactionsState)),
    filteredtranasactions: JSON.parse(localStorage.getItem('transactionFilters') || JSON.stringify({}))
}

export const transactionsReducer = (state: TransactionsDataType = initialState, action: ActionsType): TransactionsDataType => {
    switch (action.type) {
        case 'SET-TRANSACTIONS': {
            return {
                ...state,
                transactions: action.transactions,
                page: action.page,
                pages: action.pages,
                totalCount: action.totalCount
            }
        }
        case 'SET-CHANGED-TRANSACTIONS': {
            return {
                ...state, changedTransactionsState: action.changedTransactionsState
            }
        }
        case 'SET-FILTERED-TRANSACTIONS': {
            return {
                ...state, filteredtranasactions: action.filteredTransactions
            }
        }
        default: {
            return state
        }
    }
}

export const setTransactionsAC = (transactions: TransactionsType[], page: number, pages: number, totalCount: number): SetTransactionsACType => {
    return {type: 'SET-TRANSACTIONS', transactions, page, pages, totalCount}
}
export const setChangedTransactionsAC = (changedTransactionsState: TransactionTableChangeType): SetCHANGEDTransactionsACType => {
    return {type: 'SET-CHANGED-TRANSACTIONS', changedTransactionsState}
}
export const setFilteredTransactionsAC = (filteredTransactions: TransactionsParamsType): SetFilteredTransactionsACType => {
    return {type: 'SET-FILTERED-TRANSACTIONS', filteredTransactions}
}

export const fetchFilteredTransactionsTC = (dataParams: TransactionsParamsType): any => {
    return (dispatch: Dispatch) => {
        dispatch(setAppStatusAC('loading'))
        personalAreaAPI.getFilteredTransactions(dataParams)
            .then(res => {
                const action = setTransactionsAC(res.data.transactions, res.data.page, res.data.pages, res.data.totalCount);
                dispatch(action);
                localStorage.setItem('totalCountTransactions', res.data.totalCount ? res.data.totalCount.toString() : '0')
                dispatch(setAppStatusAC('succeeded'));
            })
            .catch((err) => {
                handleServerNetworkError(err, dispatch)
            })
    }
}