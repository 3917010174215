import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Suspense} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {LoginPage} from "./pages/login/Login";
import {ProtectedRoute} from "./protectedRoute/ProtectedRoute";
import './assets/styles/reset.css';


// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages

const Page404 = React.lazy(() => import('./pages/page404/Page404'))
const Page500 = React.lazy(() => import('./pages/page500/Page500'))

const App = () => {


    return (
        <BrowserRouter>
            <Suspense>

                <Routes>
                    <Route path="/" element={<Navigate to="/login" replace/>}/>
                    <Route path={'/login'} element={<LoginPage/>}/>
                    <Route path="*" element={
                        <ProtectedRoute>
                            <DefaultLayout/>
                        </ProtectedRoute>
                    }/>
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}
export default App;