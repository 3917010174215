import {
    StatisticsDataType,
    StatisticsTableChangeType,
    StatisticsType,
    TotalStatisticsType,
    TransactionTableChangeType
} from "./types";
import {Dispatch} from "redux";
import {SetAppErrorActionType, setAppStatusAC, SetAppStatusActionType} from "./appReducer";
import {personalAreaAPI, StatisticsParamsType} from "./axios.api";
import {handleServerNetworkError} from "../utils/error-utils";
import {AxiosError} from "axios";


type SetStatisticsACType = {
    type: 'SET-STATISTICS'
    statisticsArray: Array<StatisticsType>
    total: TotalStatisticsType
}
type FiltersACType = {
    type: 'SET-FILTERS'
    filters: StatisticsParamsType
}
type SetCHANGEDStatisticsACType = {
    type: 'SET-CHANGED-STATISTICS'
    changedStatisticsState: StatisticsTableChangeType
}
type ActionsType = SetStatisticsACType | FiltersACType | SetCHANGEDStatisticsACType

const changedStatisticsState = {
    date: true,
    requestsCount: true,
    hadFilter: true,
    successQuantity: true,
    successSum: true,
    passabilityPercentage: true,
    disputesQuantity: true,
    disputesSum: true,
    message: true,
    inProcessing: true
}

const initialState: StatisticsDataType = {
    statistics: [],
    total: {
        request: 0,
        transactionCount: 0,
        transactionSum: 0,
        disputeCount: 0,
        disputeSum: 0,
        passedTheFilter: 0,
        patency: 0,
        transactionProcessingCount: 0,
    },
    filters: JSON.parse(localStorage.getItem('statisticFilters') || JSON.stringify({})),
    changedStatisticsState: JSON.parse(localStorage.getItem('statisticChecks') || JSON.stringify(changedStatisticsState)),
}

export const statisticsReducer = (state: StatisticsDataType = initialState, action: ActionsType): StatisticsDataType => {
    switch (action.type) {
        case 'SET-STATISTICS': {
            return {
                ...state, statistics: action.statisticsArray, total: action.total
            }
        }
        case 'SET-FILTERS': {
            return {
                ...state, filters: action.filters
            }
        }
        case 'SET-CHANGED-STATISTICS': {
            return {
                ...state, changedStatisticsState: action.changedStatisticsState
            }
        }
        default: {
            return state
        }
    }
}
export const setStatisticsAC = (statisticsArray: Array<StatisticsType>, total: TotalStatisticsType): SetStatisticsACType => {
    return {type: 'SET-STATISTICS', statisticsArray, total}
}
export const filtersAC = (filters: StatisticsParamsType): FiltersACType => {
    return {type: 'SET-FILTERS', filters}
}
export const setChangedStaisticsAC = (changedStatisticsState: StatisticsTableChangeType): SetCHANGEDStatisticsACType => {
    return {type: 'SET-CHANGED-STATISTICS', changedStatisticsState}
}

// export const fetchStatisticsTC = (): any => {
//     return (dispatch: Dispatch) => {
//         dispatch(setAppStatusAC('loading'))
//         personalAreaAPI.getStatistics()
//             .then(res => {
//                 console.log(res.data)
//                 const action = setStatisticsAC(res.data.statistics, res.data.total);
//                 dispatch(action);
//                 dispatch(setAppStatusAC('succeeded'));
//             })
//             .catch((err) => {
//                 console.log(err)
//                 handleServerNetworkError(err, dispatch)
//             })
//     }
// }
export const fetchFilteredStatisticsTC = (dataParams: StatisticsParamsType): any => {
    return (dispatch: Dispatch) => {
        dispatch(setAppStatusAC('loading'))
        personalAreaAPI.getFilteredStatistics(dataParams)
            .then(res => {
                const action = setStatisticsAC(res.data.statistics, res.data.total);
                dispatch(action);

                dispatch(setAppStatusAC('succeeded'));
            })
            .catch((err) => {
                // console.log(err)
                handleServerNetworkError(err, dispatch)
            })
    }
}
