import React, {FC} from "react";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../state/store";
import {Navigate} from "react-router-dom";

interface Props {
    children: JSX.Element
}

export const ProtectedRoute: FC<Props> = ({children}) => {
    const isLoggedIn = useSelector<AppRootStateType, boolean>(state => state.auth.isLoggedIn);


    return (
        <>
            {
                isLoggedIn ? (
                    children
                ) : (
                    <Navigate to="/login" replace/>
                )
            }
        </>
    )
}